@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
    }
}

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@layer base {
    :root {
        --color-primary: #f34a53;
        --color-secondary: #cb3c6d;
        --color-tertiary: #6B46C1;
        --color-quaternary: #3B82F6;
        --color-quinary: #10B981;
        --color-senary: #F59E0B;
        --color-septenary: #F87171;
        --color-octonary: #6B7280;
        --color-nonary: #4B5563;

        --color-primary-light: #f34a53;
        --color-secondary-light: #cb3c6d;
        --color-tertiary-light: #6B46C1;
        --color-quaternary-light: #3B82F6;
        --color-quinary-light: #10B981;
        --color-senary-light: #F59E0B;
    }
}

html {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;

}

#root.dark {
    @apply bg-gray-900 text-gray-200;
}

#root:not(.dark) {
    @apply bg-gray-100 text-gray-900;
}

#root {
    @apply h-screen;
}

.Root {
    @apply h-screen;
}

.Main {
    @apply mx-auto max-w-full py-6 sm:px-6 px-2 lg:px-16;
}

.Footer {
    @apply mx-auto max-w-full py-6 sm:px-6 px-2 lg:px-8 items-center justify-center;
}

.Footer__container {
    @apply flex flex-col justify-center items-center;
}

.Footer__container__title {
    @apply pr-1;
}

.Footer__container__links {
    @apply text-center;
}

.MainLeaderboard {
    @apply mx-auto max-w-full;
}

.Toastify__toast {
    @apply bg-gray-900 rounded-lg shadow-lg p-4 !important;
    color: white !important;
}

.Toastify__toast-body {
    color: white !important;
    opacity: 0.8 !important;
}

.Toastify__toast:hover > .Toastify__toast-body {
    opacity: 1 !important;
}

.Toastify__progress-bar {
    background: var(--color-secondary) !important;
}

.Toastify__close-button {
    color: white !important;
    opacity: 0.8 !important;
}

.Toastify__close-button:hover {
    opacity: 1 !important;
}

.Toastify__toast-icon {
    color: white !important;
}

.animation-border::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    transform: scaleX(1);
    transition: transform .2s;
    animation: border .5s ease-in-out;
}

@keyframes border {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}

.animation-border-text_underline {
    position: relative;
    display: inline-block;
    padding-bottom: 3px;
}

.animation-border-text_underline::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    transform: scaleX(0);
    transition: transform .2s;
}

.animation-border-text_underline.disabled {
    @apply cursor-pointer
}

.animation-border-text_underline.disabled::after {
    transform: scaleX(0);
}

.animation-border-text_underline.disabled:hover::after {
    transform: scaleX(1) !important;
}

.animation-border-text_underline {
    color: var(--color-secondary);
    @apply cursor-text;
}

.animation-border-text_underline::after {
    transform: scaleX(1);
}

.animation-border-text_underline:hover {
    color: var(--color-secondary);
}


.gradient-text {
    background: linear-gradient(90deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.lb-list {
    @apply flex flex-wrap bg-gray-200 p-4 rounded-2xl w-full justify-between;
}

.lb-list-cards {
    @apply flex flex-wrap bg-gray-300 p-4 rounded-2xl w-full scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-300 scrollbar-thin;
    overflow-x: auto;
    flex-wrap: nowrap;
}

.lb-list-arrows {
    background: linear-gradient(90deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background: transparent;
    @apply float-right inline-block w-full flex content-end justify-end pt-2 h-16;
}

.lb-list-arrow {
    @apply h-10 w-10 flex items-center justify-center bg-gray-300 rounded-lg shadow-lg m-1 cursor-pointer hover:bg-gray-400 p-2;
}

.lb-card {
    @apply flex flex-col items-center justify-start bg-gray-300 rounded-lg shadow-lg m-1 p-4;
}

.lb-card-name {
    @apply text-center text-gray-700 font-bold;
}

.lb-card-score {
    @apply flex items-center bg-gray-300 rounded-lg shadow-lg m-1 p-4 min-w-[300px];
}

.lb-card-score-top {
    @apply text-center text-gray-700 font-bold mr-2 text-xl;
}

.lb-card-score-title {
    @apply text-center text-gray-700 font-bold mr-2 text-sm;
}

.lb-card-score-value {
    @apply text-center text-gray-700 mr-2 text-sm;
}

.lb-list-empty {
    @apply text-center text-gray-700 font-bold h-20 w-full flex items-center justify-center flex flex-wrap bg-gray-200 p-4 rounded-2xl bg-gray-300;
}

.lb-list-title {
    @apply text-center text-gray-800 font-bold h-16 w-full flex flex p-4 text-3xl items-center;
}

button, textarea, input, select {
    font-family: inherit;
    color: inherit;
}

button:active, button:focus, textarea:active, textarea:focus, input:active, input:focus, select:active, select:focus {
    outline: 0;
}

button, select {
    cursor: pointer;
}

@media (max-width: 1023px) {
    .bg-blur {
        @apply w-full h-full top-0 left-0 fixed z-10;
        background: hsla(0, 0%, 0%, 0.8);
    }

    .bg-blur-in {
        @apply top-1/2 absolute bg-white rounded-lg shadow-lg z-20 w-[90%] h-[90%] transform -translate-y-1/2 left-1/2 -translate-x-1/2;
    }
}

@media only screen and (max-width: 768px) {
    .Toastify__toast-container {
        padding: 20px !important;
    }
}

.board {
    @apply bg-gray-200 p-4 hover:bg-gray-300;
}

.text-outline {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    @apply text-gray-800 dark:text-gray-300;
}

.dark {
    @apply bg-gray-900 text-gray-200;
}

.light {
    @apply bg-gray-100 text-gray-900;
}
